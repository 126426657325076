<template>
  <div v-if="false">
    <p>fetchPopup: {{ fetchPopup }}</p>
  </div>
</template>
<script>
import PerfectScrollbar from 'perfect-scrollbar'
import * as moment from 'moment-timezone'

import { createNamespacedHelpers } from 'vuex'
import { FETCH_POPUP } from '@/store/notice/action'

moment().tz('Asia/Seoul')

const noticeStore = createNamespacedHelpers('noticeStore')

export default {
  name: 'Popup',
  data() {
    return {
      // Sample Data
      currentURL: window.location.href,
      componentName: this.$options.name,
    }
  },
  computed: {
    ...noticeStore.mapGetters([
      'fetchPopup',
    ]),
  },
  watch: {
    fetchPopup(newVal, oldVal) {
      if (newVal !== oldVal && newVal.length > 0) {
        this.createPopup() // 데이터가 업데이트 되면 createPopup 함수를 호출합니다.
      }
    },
  },
  created() {
    // console.log(this.componentName, 'created()')
  },
  mounted() {
    // console.log(this.componentName, 'mounted()')
    this.fetchData()
  },
  beforeDestroy() {
    // console.log(this.componentName, 'beforeDestroy()', localStorage.getItem('userData'))
    if (localStorage.getItem('userData') === null) {
      // 토큰 값이 없어지면 팝업 제거
      // document.querySelector('.popup-container').remove()
      this.closePopup()
    }
  },
  methods: {
    ...noticeStore.mapActions({
      $fetchPopup: FETCH_POPUP,
    }),
    async fetchData() {
      // console.log(this.componentName, 'fetchData()')
      await this.$fetchPopup({
        site: JSON.parse(localStorage.getItem('userData')).site,
      })
    },

    createPopup() {
      console.log(this.componentName, 'createPopup()', this.fetchPopup)

      // 기존 팝업 컨테이너가 있다면 제거
      // if (document.querySelector('.popup-container')) {
      //   document.getElementById('app').removeChild('.popup-container')
      // }
      this.closePopup()

      const popupCnt = this.fetchPopup.length
      const popupPadding = 5
      const popupMargin = 5
      const popupWidth = Math.min(400, document.documentElement.clientWidth - (popupPadding * 2) / popupCnt)
      let accumulatedLeft = popupPadding * 2 // 0 // 누적된 left 위치

      const popupContainer = document.createElement('div')
      popupContainer.classList.add('popup-container')
      popupContainer.style.visibility = 'hidden' // 초기에는 팝업 컨테이너를 숨깁니다.
      document.getElementById('app').appendChild(popupContainer) // 팝업 컨테이너를 먼저 추가

      // 팝업 생성
      this.fetchPopup.forEach((item, index) => {
        const popupEl = document.createElement('div')
        // 팝업의 속성 추가
        popupEl.classList.add('popup-list', 'card', 'no-body')
        popupEl.setAttribute('data-id', `popup-${item.idx}`)

        // 팝업의 스타일 설정
        popupEl.style.backgroundColor = '#121315'
        popupEl.style.padding = `${popupPadding}px`
        popupEl.style.minWidth = `${popupWidth}px`
        // popupEl.style.width = `${noticeWidth}px`;
        // previousNoticeWidth = noticeWidth;
        // console.log(popupEl.offsetWidth);
        popupEl.style.position = 'fixed'
        popupEl.style.top = 0
        // popupEl.style.left = `${index * (popupWidth + popupMargin)}px`
        popupEl.style.zIndex = 999
        popupEl.style.display = 'inline-block'
        // popupEl.style.visibility = 'hidden' // 초기에는 팝업을 숨깁니다.

        popupEl.innerHTML = `
            <div class="card-header">
              <h5 class="card-title">${item.title}</h5>
              <button type="button" class="popup-close close">
                <span>&times;</span>
              </button>
            </div>
          `
        popupEl.innerHTML += `
            <div class="card-body">
              <div class="scroll-content" style="max-height: 60vh; position: relative;">
                <p class="card-text">${item.content.replace(/<img /g, '<img style="display: block; margin: auto;" ')}</p>
              </div>
            </div>
          `
        popupEl.innerHTML += `
            <div class="card-footer d-flex justify-content-between align-items-center">
              <div class="form-check">
                <div class="popup-check check-cursor" style="transform: scale(1.2);">
                  <input class="form-check-input popup-close" type="checkbox" value="" id="dontShow-${item.idx}">
                  <label class="form-check-label" for="dontShow-${item.idx}">
                    오늘 하루동안 보지 않기
                  </label>
                </div>
              </div>
              <button type="button" class="popup-close btn btn-secondary">닫기</button>
            </div>
          `
        popupContainer.appendChild(popupEl)

        // 실제 너비를 기반으로 left 위치 설정
        const actualWidth = popupEl.offsetWidth
        popupEl.style.left = `${accumulatedLeft}px`
        accumulatedLeft += actualWidth + popupMargin

        const closeButtons = popupEl.querySelectorAll('.popup-close')
        closeButtons.forEach(button => {
          button.addEventListener('click', () => {
            this.closePopup(item.idx)
          })
        })
      })

      Array.from(document.querySelectorAll('.scroll-content'))
        .map(el => new PerfectScrollbar(el, {
          wheelPropagation: false,
        }))

      document.querySelector('.popup-container').style.visibility = 'visible'
    },

    closePopup(idx) {
      if (idx) {
        const popupEl = document.querySelector(`.popup-container .popup-list[data-id="popup-${idx}"]`)
        if (popupEl) {
          const dontShowAgain = popupEl.querySelector('.popup-check > input').checked
          if (dontShowAgain) {
            this.setCookie(`po${idx}`, 1, 0, '/')
          }
          popupEl.parentNode.removeChild(popupEl)
        }
      } else {
        // idx가 주어지지 않은 경우 모든 팝업을 제거
        const popupContainers = document.querySelectorAll('.popup-container')
        popupContainers.forEach(container => {
          container.parentNode.removeChild(container)
        })
      }
    },

    setCookie(name, value, expires, path = '/', domain = document.domain, secure = false) {
      let expireTime

      switch (expires) {
        case 0: // 5초 후 만료
          expireTime = moment().add(5, 'seconds').utc().format('ddd, DD MMM YYYY HH:mm:ss [GMT]')
          break
        case 1: // 1일 후 만료
          expireTime = moment().add(1, 'days').endOf('day').utc()
            .format('ddd, DD MMM YYYY HH:mm:ss [GMT]')
          break
        case 7: // 7일 후 만료
          expireTime = moment().add(7, 'days').endOf('day').utc()
            .format('ddd, DD MMM YYYY HH:mm:ss [GMT]')
          break
        default: // 기본 설정 (예: 30초 후 만료)
          expireTime = moment().add(30, 'seconds').utc().format('ddd, DD MMM YYYY HH:mm:ss [GMT]')
      }

      const cookieValue = encodeURIComponent(value)
      const cookieDomain = domain ? `; domain=${domain}` : ''
      const cookieSecure = secure ? '; secure' : ''

      document.cookie = `${name}=${cookieValue}${expireTime}; path=${path}${cookieDomain}${cookieSecure}`
    },
  },
}
</script>
<style>
.check-cursor * {
  cursor: pointer;
}
</style>
